import React, { useEffect, useState } from 'react'
import { FaHeadphones  } from "react-icons/fa";
import RadioxPackage from '../Radios/RadioxPackage';
import { useAppContext } from '../../Context/ApiProviders';


const CardPackage = ({amount, keys, minutes, id}) => {

    const {paquetes, dispatchPaqueteSelected, infoUsers} = useAppContext();

    const [descuento, setdescuento] = useState(0)
    const [valorFinal, setvalorFinal] = useState(0)
    
    const handleChangePackage = () => {
        const searchPaquete =  paquetes.find(paquete => paquete.id === id);
        dispatchPaqueteSelected({
            type:'ADD_PAQUETES_SELECTED',
            value: searchPaquete
        })
     }

     useEffect(() => {
        if(infoUsers.birthday_this_month === false){

            if(infoUsers.member_type && infoUsers.member_type.minute_discount){

                setvalorFinal(amount - (amount * infoUsers.member_type.minute_discount / 100));
            }else{
                setvalorFinal(amount);
            }
        }else{
            setvalorFinal(amount - (amount * 15 / 100));
        }
     }, [])
     
     

  return (
    
    <div className="max-w-sm p-3 bg-white border border-gray-200 rounded-md">
        <FaHeadphones className='text-purple-800 text-lg' />       
        <a href="#">
            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-700 dark:text-white"> Consulta de {minutes} minutos</h5>
        </a>
        <p className="mb-3 text-gray-700 dark:text-gray-400 font-medium"> 
        
                <span className='text-gray-800 mr-3'>${valorFinal && valorFinal.toFixed(2)}  {infoUsers?.member_type?.name && (<span className='line-through text-gray-500 ml-4'>${amount.toFixed(2)}</span>)} </span>
        </p>

        <div className="flex items-center mb-4">

            <RadioxPackage name='compra-paquete' id={id} value={valorFinal && valorFinal.toFixed(2)} evento={handleChangePackage} />
            <label for={id} className="ms-2 text-sm font-medium text-gray-700 dark:text-gray-300">Comprar</label>
          
        </div>
    </div>
  )
}

export default CardPackage