import RadioxComponent from '../../../Components/Radios/RadioxComponent'

const RadioGroupCanal = ({name, value, id, nombreEtiqueta, handleChange, checked}) => {

  return (
    <div className="flex items-center mt-3 mb-3">
       
        <RadioxComponent name={name} value={value} id={id} evento={handleChange} checked_={checked}  />
        <label htmlFor={id} 
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> {nombreEtiqueta} </label>
    </div>
  )
}

export default RadioGroupCanal