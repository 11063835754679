import { toast } from 'sonner';

export const NotificacionesToastSuccess = (mensaje) => {
    
    toast.success(`${mensaje}`, {
        style: {
            background: '#14532d',
            color:'#fff',
        },
        duration: 10000,
    });

}


export const NotificacionesToastError = (mensaje) => {
    
    toast.error(`${mensaje}`, {
        style: {
            background: '#b91c1c',
            color:'#fff',
        },
        duration: 10000,
    });

}
