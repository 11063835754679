import React, { useContext } from 'react'


const RadioxComponent = ({name, value, id, evento, estado, checked_}) => {
  return (
      
    <input  id={id} 
            type="radio" 
            value={value}
            name={name}
            onChange={evento}
            checked={checked_}
            className="w-4 h-4 text-purple-600 bg-gray-100 border-2 border-purple-600 focus:ring-purple-700 
                     focus:ring-2" />

  )
}

export default RadioxComponent