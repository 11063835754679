import React, { useEffect } from 'react'
import { PortalPagoContent, PortalPagoContext } from '../../Context/PortalPagoContent';
import Formulario from './Formuulario/Formulario';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../Context/ApiProviders';


const VerificationCode = () => {

  const {infoTelefono} = useAppContext();
  
  const location = useLocation();

  const navigate  = useNavigate ();

  useEffect(() => {

    if(infoTelefono.length == 0){
      navigate('/');
    }
   
  }, []);

  return (
    <div className="py-2 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-10 sm:py-16 lg:px-6">


          <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-1">
              <div>
                  <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white">Verificación en dos pasos</h2>
                  <p className="mb-8 font-light lg:text-md text-justify">
                      Por favor, introduzca el código enviado a su número de teléfono o correo electrónico.
                  </p>
            </div>
          </div>


       <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-1 sm:grid-cols-1 gap-1">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400 ">
                <PortalPagoContent>
                    <Formulario />
                </PortalPagoContent>
            </div>
       </div>
    </div>
  )
}

export default VerificationCode