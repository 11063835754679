import React, { useState } from 'react';
import logoblue from '../../assets/logo-blanco.png';
import SelectFlags from './SelectFlags';





const HeaderComponent = () => {
   
    

  return (
    <div>
            <header className=''>
                <nav className="bg-gradient-purple border-gray-200 dark:bg-gray-800">
                    <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl px-4 md:px-6 py-2.5 max-w-  lg:mt-0 sm:mb-10">
                        <a href="https://agenda.ninoprodigio.com/" className="flex items-center">
                        <img src={logoblue} className="mr-3 h-30 max-w-180" alt="Nino Prodigio Logo" />  
                        </a>
                        <div className="flex items-center">

                            <div class="text-left lg:w-96 sm:w-80">
                                
                                <div className='flex items-center pt-1'>

                                    <SelectFlags defaultCountry="tel:18004110112" className="mt-5" />
                                </div>
                            </div>
  
                        </div>
                    </div>
                </nav>
                
            </header>

    </div>
  )
}

export default HeaderComponent