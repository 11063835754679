import { createContext, useContext, useReducer, useState } from 'react';
import { obtenerCodigo, veririfcarCodigo, getInfoCLient, getListaPaquetes, getSelecCustomer, 
        postMeeting, postPay, postCreateClient, patchUpdateClient, getListaPaquetesVentas
    } from '../Services/ApiHttp';
import { getCountries, getCountrieUbication } from '../Services/ApiHttpExterno';

const AppContext = createContext();
const useAppContext = () => {
    return useContext(AppContext);
}

//* Inicialización
const initialInfoTelefono = {
    telefono : []
};

const initialInfoUsers = {
    users : []
}

const initialInfoPaquetes = {
    paquetes : []
}

const initialInfoSelectedPaquete = {
    selectPaquete : []
}

const initialInfotarjeta = {
    cardInfo : []
}

const initialInfoDiscount = {
    discount : []
}

const inititalInfoCountries = {
    countries : []
};

const initialcountrieUbication = {
    countrieUbication : []
}

const reducerInfoTel = (state, action) => {
        
    switch (action.type) {
        case 'ADD_TELEFONO': {
            return {
                ...state,
                telefono: action.value
            }
        }
    }
    return state;
}

const reducerInfoUsers = (state, action) => {
    switch (action.type) {
        case 'ADD_USERS': {
            return {
                ...state,
                users: action.value
            }
        }
        case 'UPDATE_USER': {
            return {
                ...state,
                users: action.value
            }
        }
    }
    return state;
 }
 
 const reducerInfoPaquete = (state, action) => {
        
    switch (action.type) {
        case 'ADD_PAQUETES': {
            return {
                ...state,
                paquetes: action.value
            }
        }
    }
    return state;
}


const reducerInfoSelectedPaquete = (state, action) => {
    switch (action.type) {
        case 'ADD_PAQUETES_SELECTED': {
            return {
                ...state,
                selectPaquete: action.value
            }
        }
    }
    return state;
}

const reducerInfoCards = (state, action) => {
        
    switch (action.type) {
        case 'ADD_CARDS': {
            return {
                ...state,
                cardInfo: action.value
            }
        }
    }
    return state;
}

const reducerInfoDiscount = (state, action) => {
        
    switch (action.type) {
        case 'ADD_DISCOUNT': {
            return {
                ...state,
                discount: action.value
            }
        }
    }
    return state;
}

const reducerCountries = (state, action) => {
    switch (action.type){
        case 'ADD_COUNTRIES': {
            return {
            ...state,
            countries: action.value
            }
        }
    }
    return state;
}

const reducerContrieUbication = (state, action) => {
    switch (action.type){
        case 'ADD_COUNTRIE_UBICATION': {
            return {
            ...state,
            countrieUbication: action.value
            }
        }
    }
    return state;
}


const ApiProviders = ({children}) => {
    
    const [prueba, setprueba] = useState('prueba');
    const [stateInfoTelefono, dispatchInfoTelefono] = useReducer(reducerInfoTel, initialInfoTelefono);
    const [stateUser, dispatchUser] = useReducer(reducerInfoUsers, initialInfoUsers);
    const [statePaquete, dispatchPaquete] = useReducer(reducerInfoPaquete, initialInfoPaquetes);
    const [statePaqueteSelected, dispatchPaqueteSelected] = useReducer(reducerInfoSelectedPaquete, initialInfoPaquetes);
    const [stateInfoCard, dispatchInfoCard] = useReducer(reducerInfoCards, initialInfotarjeta);
    const [stateInfoDiscount, dispatchInfoDiscount] = useReducer(reducerInfoDiscount, initialInfoDiscount);
    const [stateCountries, dispatchCountries] = useReducer(reducerCountries, inititalInfoCountries);
    const [stateCountrieUbication, dispatchCountrieUbication] = useReducer(reducerContrieUbication, initialcountrieUbication);
    //! Metodos
    const obtenerCodigoProvider = async (param) => {
        const resp = await obtenerCodigo(param);
        return resp;
    }

    const verificarCodigoProvider = async (param) => {
        const resp = await veririfcarCodigo(param);
        if(resp.user){
            sessionStorage.setItem('token', resp.token);
            const dataUser = {
                'client_id': resp.user.uuid
            }
            dispatchCountrieUbication({
                type : 'ADD_COUNTRIE_UBICATION',
                value : {
                    country : resp.user.country
                }
            });
            const respUsers = await getInfoCLient(dataUser, sessionStorage.getItem('token'));
            dispatchUser({
                type:'ADD_USERS',
                value:respUsers
            });
            
            return 1;
        }else{
            sessionStorage.setItem('token_create', resp.token);
            return 2;
        }
        
    }

    const getPaquetesprovider = async(param) => {
        const data = {
            camp:param
        }
        const resp = await getListaPaquetes(data);
        dispatchPaquete({
            type:'ADD_PAQUETES',
            value: resp
        })
        return resp;
    }

    const getPaquetesVentasProvider = async(param) => {
        const data = {
            'client_id':param
        }
        const resp = await getListaPaquetesVentas(data);
        return resp;
    }

    const getSelectCustomerProvider = async(param) => {
        const resp = await getSelecCustomer(param, sessionStorage.getItem('token'));
        return resp;
    }

    const postMeetingProvider = async(param) => {
        const resp = await postMeeting(param, sessionStorage.getItem('token'));
        return resp;
    }

    const handleComprar = async (param) => {
        const resp = await postPay(param);
        return resp;
    }

    const postCreateCardClientProvider = async(param) => {
        const resp = await postCreateClient(param, sessionStorage.getItem('token_create'));
        if(resp.user){
            sessionStorage.setItem('token', resp.token);
            const dataUser = {
                'client_id': resp.user.uuid
            }

            const respUsers = await getInfoCLient(dataUser, sessionStorage.getItem('token'));
            dispatchUser({
                type:'ADD_USERS',
                value:respUsers
            });
            sessionStorage.removeItem('token_create');
            return 1;
        }else{
            return 0;
        }
    }

    const patchUpdateClientProvider = async(param) => {
        const resp = await patchUpdateClient(param);
        return resp;
    }

    const getCountriesProvider = async() => {
        const resp = await getCountries();
        const regionesAFiltrar = ["Americas" , "Europe" ];
        const filtrarRegion = resp.filter(region => regionesAFiltrar.includes(region.region));
        dispatchCountries({
            type:'ADD_COUNTRIES',
            value : filtrarRegion
        })
        return resp;
    }

    
    const getCountrieUbicationProvider = async() => {
        const resp = await getCountrieUbication();
        return resp;
    }



  return (
    <AppContext.Provider  
        value={{
            prueba,
            obtenerCodigoProvider,
            infoTelefono : stateInfoTelefono.telefono,
            dispatchInfoTelefono,
            verificarCodigoProvider,
            infoUsers : stateUser.users,
            dispatchUser,
            getPaquetesprovider,
            paquetes: statePaquete.paquetes,
            dispatchPaqueteSelected,
            paqueteSelected : statePaqueteSelected.selectPaquete,
            getSelectCustomerProvider,
            postMeetingProvider,
            handleComprar,
            dispatchInfoCard,
            cards : stateInfoCard.cardInfo,
            dispatchInfoDiscount,
            discount: stateInfoDiscount.discount,
            postCreateCardClientProvider,
            patchUpdateClientProvider,
            getCountriesProvider,
            countriesAPI : stateCountries.countries,
            getCountrieUbicationProvider,
            countrieSelected : stateCountrieUbication.countrieUbication,
            dispatchCountrieUbication,
            getPaquetesVentasProvider,
            dispatchPaquete
        }}
    >
        {children}
    </AppContext.Provider>
  )
}

export {
    ApiProviders,
    useAppContext
}
