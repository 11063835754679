import 'react-phone-number-input/style.css'
import { PortalPagoContent } from '../../Context/PortalPagoContent';

import Formulario from './Formulario/Formulario';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../Context/ApiProviders';


export const PhoneValidation = () => {


  return (
    <div className="py-2 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-10 sm:py-16 lg:px-6">


      <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-1">
          <div>
            <h2 className="text-2xl tracking-tight font-medium text-gray-800 dark:text-white">
              Conéctate con uno de los psíquicos expertos del Niño Prodigio
            </h2>
            <p className="mb-1 mt-2 font-light lg:text-md text-justify">
                Ahora puedes adquirir paquetes de minutos con los que podrás conectarte con un equipo especializado de tarotistas y videntes que te ayudarán a encontrar respuesta a tus dudas y solución a los problemas por los que estás atravesando de forma completamente segura y confiable.
            </p>
          </div>
      </div>

      <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-1">
          <div>
            <h2 className="mb-1 text-2xl tracking-tight font-medium text-gray-900 dark:text-white">¡Ayúdanos a validar tu número de teléfono! </h2>
              <p className="mb-1 mt-3 font-light lg:text-sm text-justify">
                    Proporcione su número de teléfono para validar su información. Recibirá un código de verificación, válido por 10 minutos, 
                    para completar el proceso. Mantenga el código confidencial. Si no ha solicitado este código, puede ignorar este mensaje. 
                    Si su número no está registrado, será redirigido al formulario de creación de cuenta. <span className='font-medium'>¡Gracias!</span>
              </p>
        </div>
      </div>

      <div className="grid grid-cols-2  md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-1">
                    
        <div className="text-gray-500 sm:text-lg dark:text-gray-400 ">
            <PortalPagoContent>
                <Formulario />
            </PortalPagoContent>
        </div>

        {/* <button onClick={() => methodDoesNotExist()}>Break the world</button> */}
        
      </div>
  </div>
  )
}
