import React, {useEffect, useRef} from 'react'
/* import PhoneInput from 'react-phone-number-input' */
import { parsePhoneNumber  } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' 

import intlTelInput  from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const InputPhone = ({phones, setPhones}) => {

  return (
    <div>

      <PhoneInput
                inputClass='mb-5 text-cyan-900 font-medium w-full border-2 border-red-600 bg-blue-600'
                country={'us'}
                value={phones}
                onChange={setPhones}
                inputProps={{
                  required: true,
                }}
                enableSearch
                inputStyle={{ width:'100%' }}
              />
    </div>
  )
}

export default InputPhone