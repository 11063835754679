import React from 'react'

const CheckBoxComponent = ({id, value, evento}) => {
  return (
    <div>
        <input  id={id} 
                type="checkbox" 
                value={value}
                onChange={evento}
                className="w-4 h-4 text-purple-600 bg-gray-100 border-2 border-purple-600 rounded focus:ring-purple-900 
                focus:ring-2" />
    </div>
  )
}

export default CheckBoxComponent