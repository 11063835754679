import axios from "axios";

const baseUrl = import.meta.env.VITE_BACKEND_URL 

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
  };

  const config = {
    headers: headers,
    params: {}
        
  };

export const obtenerCodigo = async (param) => {
    
    const config = {
        headers: headers,
        params: param
     };

    headers.params = param
    const res = [];
     if(param.method !== 'email'){

         const response = await axios.get(`${baseUrl}api/code/send`, config);
         return response;
     }else{
        return 'correo eletronico'
     }

};

export const veririfcarCodigo = async (param) => {
    const config = {
        headers: headers,
        params: param
     };
    const response = await axios.get(`${baseUrl}api/code/verify`, config);
    return response.data;
}

export const getInfoCLient = async (param, token) => {

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    //'Accept:':'*/*'
    'Authorization': `Bearer ${token}` // Ejemplo de encabezado de autorización
  };
  
  const config = {
      headers: headers,
      params: param
   };
  const response = await axios.get(`${baseUrl}api/client/info`, config);
  return response.data;
}

export const getInfoTarjetasCliente = async (param) => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      //'Accept:':'*/*'
      'Authorization': `Bearer ${param.token}` // Ejemplo de encabezado de autorización
    };

    const config = {
      headers: headers,
      params: param
   };
   const response = await axios.get(`${baseUrl}api/client/cards/minutes`, config);
   return response.data;
}

export const getListaPaquetes = async (param) => {
  
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    //'Accept:':'*/*'
    'Authorization': `Bearer ${sessionStorage.getItem('token')}` // Ejemplo de encabezado de autorización
  };
  
  const config = {
      headers: headers,
      params: param
   };
  const response = await axios.get(`${baseUrl}api/client/packages/minutes`, config);
  return response.data;
}

export const getListaPaquetesVentas = async (param) => {
  
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    //'Accept:':'*/*'
    'Authorization': `Bearer ${sessionStorage.getItem('token')}` // Ejemplo de encabezado de autorización
  };
  
  const config = {
      headers: headers,
      params: param
   };
  const response = await axios.get(`${baseUrl}api/client/show/payment/pending/minutes`, config);
  return response.data;
}

export const postPay = async (param) => {

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${sessionStorage.getItem('token')}` // Ejemplo de encabezado de autorización
  };


  const response = await axios.post(`${baseUrl}api/client/pay/minutes`, param, {
    headers
  });
  return response;

}

export const  postCreateClient = async(param, token) => {

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    //'Accept:':'*/*'
    'Authorization': `Bearer ${token}` // Ejemplo de encabezado de autorización
  };

  const response = await axios.post(`${baseUrl}api/client/create`, param, {
    headers
  });
  return response.data;

}

export const  postCreateCardClient = async(param) => {

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    //'Accept:':'*/*'
    'Authorization': `Bearer ${sessionStorage.getItem('token_create')}` // Ejemplo de encabezado de autorización
  };

  const response = await axios.post(`${baseUrl}api/client/create/card/minutes`, param, {
    headers
  });
  return response; 

}

export const getSelecCustomer = async (param) => {
  
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    //'Accept:':'*/*'
    'Authorization': `Bearer ${sessionStorage.getItem('token')}` // Ejemplo de encabezado de autorización
  };

  const configuracion = {
      headers : headers,
      params : param
  }
  try {

      return await axios.get(`${baseUrl}client/customer`, configuracion)
      .then((resp) =>  {
          return {status : resp.status, data : resp};
      })
      .catch((error) => {
          return {status : error.response.status, data : error};
      });
     
  } catch (error) {//
      return error
  }
}

export const postMeeting = async (param) => {
  const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}` 
    };
   
  const response = await axios.post(`${baseUrl}calendar/pay/meeting`, param, {
      headers
  });
  return response;
}

export const patchUpdateClient = async (param) => {
  const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}` // Ejemplo de encabezado de autorización
    };
   
  const response = await axios.patch(`${baseUrl}api/client`, param, {
      headers
  });
  return response;
}


