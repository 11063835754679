import React from 'react'
import SummaryComponent from './SummaryComponent/SummaryComponent';

const SalesSummarys = () => {

  return (
    <div className="py-6 px-4 mx-auto space-y-16 max-w-xl lg:space-y-20 sm:py-16 lg:px-6">
          <SummaryComponent />
    </div>
  )
}

export default SalesSummarys