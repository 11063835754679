import { useAppContext } from '../../../Context/ApiProviders';

const InfoClienteComponent = ({}) => {

    const {infoUsers, infoTelefono} = useAppContext();
  
  return (
    <div>
        {infoUsers ? (
                <dl className="max-w-md text-gray-900 divide-y divide-gray-100 border-r p-5">
                        <div className="flex flex-row sm:flex-row sm:justify-between pb-1">
                            <dt className="lg:text-lg mb-1 text-gray-600 md:text-sm">Número de la suerte</dt>
                            <dd className="text-xl font-bold text-purple-600 sm:ml-1">{infoUsers.id}</dd>
                        </div>
                        <div className="flex justify-between pb-1">
                            <dt className="lg:text-lg mb-1 text-gray-600 md:text-sm">Nombre</dt>
                            <dd className="text-lg  text-gray-800">{infoUsers.full_name}</dd>
                
                        </div>
                        <div className="flex justify-between py-1">
                            <dt className="lg:text-lg mb-1 text-gray-600 md:text-sm">Correo Electronico</dt>
                            <dd className="text-lg text-gray-800">{infoUsers.email}</dd>
                        </div>
                        <div className="flex justify-between pt-1">
                            <dt className="lg:text-lg mb-1 text-gray-600 md:text-sm ">Número de teléfono</dt>
                            <dd className="text-lg text-gray-800">{infoTelefono.target}</dd>
                        </div>
                        <div className="flex justify-between pt-1">
                            <dt className="lg:text-lg mb-1 text-gray-600 md:text-sm ">Fecha de cumpleaños</dt>
                            <dd className="text-lg text-gray-800 flex items-center justify-center">
                                {infoUsers.birthday_this_month === true ? 
                                    (<svg class="w-6 h-6 mr-2 text-green-600 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z"/>
                                    </svg>
                                    ) : false
                                }
                                {infoUsers.birthdate !== null ? infoUsers.birthdate : 'No tiene'}
                            </dd>
                        </div>
                        <div className="flex justify-between pt-1">
                            <dt className="lg:text-lg mb-1 text-gray-600 md:text-sm ">Minutos disponibles</dt>
                            <dd className="text-lg  text-gray-800">{infoUsers.time_allocated} Min</dd>
                        </div>
                        <div className="flex justify-between pt-1">
                            <dt className="lg:text-lg mb-1 text-gray-600 md:text-sm ">Campaña</dt>
                            <dd className="text-lg  text-gray-800">{infoUsers.campaign.country}</dd>
                        </div>
                        {infoUsers?.member_type?.name && (<div className="flex justify-between pt-1">
                            <dt className="lg:text-lg mb-1 text-gray-600 md:text-sm ">Membresia</dt>
                            <dd className="text-lg  text-gray-800 flex items-center justify-center">
                                <svg class={`w-6 h-6 mr-2
                                            ${infoUsers.member_type.name === 'BASIC' ? "text-red-950" : 
                                            infoUsers.member_type.name === 'VIP' ? "text-gray-400" : "text-yellow-400"}`} 
                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/>
                                    <path fill-rule="evenodd" d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" clip-rule="evenodd"/>
                                    <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z"/>
                                </svg>
                             {infoUsers.member_type.name }
                             </dd>
                        </div>)}
                    </dl>
            ) 
        : (<div role="status" className="max-w-sm animate-pulse">
                        <div className="flex flex-col py-1">
                            <dt className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-1 w-20"></dt>
                            <dd className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-1"></dd>
                        </div>
                        <div className="flex flex-col py-1">
                            <dt className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-1 w-20"></dt>
                            <dd className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-1"></dd>
                        </div>
                        <div className="flex flex-col py-1">
                            <dt className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-1 w-20"></dt>
                            <dd className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-1"></dd>
                        </div>
                    <span className="sr-only">Loading...</span>
                </div>)}
    </div>
  )
}

export default InfoClienteComponent