import axios from "axios";

export const getCountries = async () => {
   
    try {
        const resp = await axios.get(`/api.json`);
        return resp.data;
    } catch (error) {
        return {error: error, message: error.message}
    }
}

export const getCountrieUbication = async () => {
    try {
        const response = await axios.get('https://ipinfo.io/json');
        return response.data;
    } catch (error) {
        return {error: error, message: error.message}
    }
}