import { useContext } from 'react'
import InputPhone from '../../../Components/InputPhone/InputPhone'
import { PortalPagoContext } from '../../../Context/PortalPagoContent';
import 'react-phone-input-2/lib/style.css'
import 'intl-tel-input/build/css/intlTelInput.css'


const InputPhoneGroup = () => {

  const {objStatusPhone} = useContext(PortalPagoContext);

  return (
    <div className='my-5 '>
        <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Ingresa tu numero de telefono 
            <span className="text-xs text-gray-500">(Ejemplo: +1325685568)</span>
        </label>
        <InputPhone phones={objStatusPhone.numeroTel} setPhones={objStatusPhone.setNumeroTel} />
    </div>
  )
}

export default InputPhoneGroup