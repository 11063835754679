import React, { useEffect } from 'react'
import CardPackage from '../../../Components/Cards/CardPackage';
import CardPackageSkeleton from '../../../Components/Cards/CardPackageSkeleton';
import { useAppContext } from '../../../Context/ApiProviders';


const PaquetesMinutos = () => {
    
  const {infoUsers, getPaquetesprovider, paquetes, getPaquetesVentasProvider, dispatchPaquete} = useAppContext();
 
    useEffect(() => {

      const getPaquetes = async () => {
          const resp = await getPaquetesVentasProvider(infoUsers.id);
          if(resp.length > 0){
            dispatchPaquete({
              type:'ADD_PAQUETES',
              value: resp
          })
          }else{
            await getPaquetesprovider(infoUsers.campaign_line.campaign_name);
          }

      }
      getPaquetes();
      
    }, [])
    
    
  return (
     <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 ">
            {
                paquetes ? ( paquetes.map(paquete => (  <CardPackage key={paquete.id} value={paquete.id} amount={paquete.amount} id={paquete.id}
                    minutes={paquete.minutes} handle={paquete} /> )) ): 
                ( <CardPackageSkeleton />   )
            }
            
    </div> 
  )
}

export default PaquetesMinutos