import React from 'react'
import CheckBoxComponent from '../../../Components/CheckBox/CheckBoxComponent'


const ChecboxGroupTermino = ({id, value, hancleEvento}) => {
 
  return (
    <div className="flex items-center mt-5">
        <CheckBoxComponent id={id} value={value} evento={hancleEvento} />
        <label htmlFor={id} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Acepto politica de tratamiento de datos
        </label>
    </div>
  )
}

export default ChecboxGroupTermino