import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../Context/ApiProviders';
import {useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import AlertErrorComponent from '../../../Components/AlertErrorComponent/AlertErrorComponent';
import visa from '../../../assets/visa.png';
import amex from '../../../assets/american-express.png';
import mastercard from '../../../assets/Mastercard.png';
import discover from '../../../assets/discover.png';


const FormPago = () => {

    const stripe = useStripe();
    const elements = useElements();

    const {
            paqueteSelected, 
            dispatchInfoDiscount, 
            infoUsers,  
            handleComprar, 
            dispatchInfoCard, 
            getCountriesProvider, 
            countriesAPI,
            countrieSelected,
            infoTelefono} = useAppContext();
    
    const [error, setError] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [disableButton, setDisableButton] = useState(false)
    const [nameEtiqueta, setnameEtiqueta] = useState('Procesar pago');
    const [postal, setPostal] = useState('')
    const [listCountries, setListCountries] = useState('');
    const [viewPostal, setViewPostal] = useState(false);

    const [formData, setFormData] = useState({
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
      });


    const navigate = useNavigate();

    useEffect(() => {
        const getCountries = async() => {
            await getCountriesProvider();
        };
        getCountries();
    }, []);

    useEffect(() => {

        const getCountriesUbication = async() => {
            setListCountries(countrieSelected.country);
           if(countrieSelected.country === 'US'){
                setViewPostal(true);
           }else{
                setViewPostal(false);
           }
        };
        getCountriesUbication();
      }, [])
    

    const handleInputChange = (event) => {
        setFormData({
          ...formData,
          [event.elementType]: event,
        });
    };

    const handleChangePOstal = (event) => {
        setPostal(event.target.value)
    }
 
    const handleChangeCountries = (event) => {
    setListCountries(event.target.value)
    if(event.target.value === 'US'){
        setViewPostal(true);
    }else{
        setViewPostal(false);
    }
    }
    

    const handleSubmit = async(e) => {
        e.preventDefault();

        if(!paqueteSelected){
            setMessageError('Debe seleccionar un paquete de consulta ');
            setError(true);
            setDisableButton(false);
            return;
        }

        if (!stripe /* || !elements */) {
            setDisableButton(false);
            return;
        }
        
        const {error: submitError} = await elements.submit();
        if (submitError) {
            setDisableButton(false);
        return;
        }

        const cardElement = elements.getElement(CardNumberElement);
        const expElement = elements.getElement(CardExpiryElement);
        const cvcElement = elements.getElement(CardCvcElement);

        let expMonth, expYear, cvc;
        if (expElement) {
            const expState = expElement._controller._state;
            expMonth = expState ? expState.expMonth : null;
            expYear = expState ? expState.expYear : null;
        }
        if (cvcElement) {
            const cvcState = cvcElement._controller._state;
            cvc = cvcState ? cvcState.value : null;
        }

        if( (formData.cardNumber.complete === false || formData.cardNumber === '') 
            || (formData.cardExpiry.complete === false || formData.cardExpiry === '') 
            ||  (formData.cardCvc.complete === false || formData.cardCvc === '')
            ||  (listCountries === '') ){
            setMessageError('Debe completar los campos para procesar el pago');
            setError(true);
            setDisableButton(false);
            return
       }

       if(listCountries === 'US' && !postal){
        setMessageError('Codigo postal se requiere para el pais de Estasdos unidos');
        setError(true);
        setDisableButton(false);
        return;
      }

        setDisableButton(true);
        const{ paymentMethod, error } = await stripe.createPaymentMethod({      
            type:'card',
            card: cardElement,
            billing_details: {
                email : infoUsers.email,
                name : infoUsers.full_name,
                exp_month: expMonth,
                exp_year: expYear,
                cvc: cvc,
                address: {
                  postal_code: postal
                }
            }    
        });    
        console.log(error)
       

        const dataCustomer = {
            email : infoUsers.email,
            name : infoUsers.full_name
        };
        //* validar si tiene descuento por  membresia o descuento por cumpleaños
       
        let bit = infoUsers.birthday_this_month;
        let memb = infoUsers.member_types_id;
        let descMem = infoUsers.member_type?.minute_discount
        let discou = false;
        let porcen = 0;
        if(bit === true && memb !== null){
            if(descMem > 15){
                porcen = descMem;
            }else{
                porcen = 15;
            }
            discou = true;
        }else if(bit === false && memb !== null){
            discou = true;
            porcen = infoUsers.member_type?.minute_discount;
        }else if(bit === true && memb === null){
            discou = true;
            porcen = 15;
        }

        dispatchInfoDiscount({
            type:'ADD_DISCOUNT',
            value : {
                descuento : discou,
                porcentaje : porcen
            }
        })

        if(error) {
            if (error.type === 'card_error') {
                // Manejo de errores específicos de la tarjeta
                switch (error.decline_code) {
                    case 'insufficient_funds':
                        setMessageError('La tarjeta no tiene fondos suficientes.');
                        break;
                    case 'lost_card':
                        setMessageError('La tarjeta ha sido reportada como perdida.');
                        break;
                    case 'stolen_card':
                        setMessageError('La tarjeta ha sido reportada como robada.');
                        break;
                    case 'expired_card':
                        setMessageError('La tarjeta ha expirado.');
                        break;
                    default:
                        setMessageError(`Error de tarjeta: ${error.message}`);
                        break;
                }
                setError(true);
                setDisableButton(false);
            } else {
                // Manejo de otros errores
                alert(`Error: ${error.message}`);
            }
            //setMessageError(`${error.code}, ${error.message}`);
           
        }else{

            const param = {
                "Type":"minute",
                "payment":{
                    "id":paqueteSelected.id,
                    "valor":paqueteSelected.amount,
                    "minutes":paqueteSelected.minutes,
                    "sales_type_id": paqueteSelected.plan_type_id
                },
                "pp":false,
                "merchant":"Stripe",
                "client_id" : infoUsers.id,
                "observation":"Compra Portal minutos",
                "paymentMethod" : paymentMethod,
                 "discount":{
                    "isdiscount":discou,
                    "percentage":porcen,
                },
                "purchased_minute_id" :  paqueteSelected.purchased_minute_id !== null ? paqueteSelected.purchased_minute_id : null,
                "clientfornumber" : infoTelefono.target
            }
             const resp = await handleComprar(param);
             if(resp.status == 200){
                dispatchInfoCard({
                    type:'ADD_CARDS',
                    value: paymentMethod.card
                })
                setDisableButton(false);
                navigate('/sales-summarys');
            }else{
                setError(true);
                setMessageError('Hubo un problema al al registrar la compra');
                setDisableButton(false);
            } 
            setError(false);
            setMessageError('');
        }

    }

    const handleDevolverPortalTienda = () => {
        sessionStorage.removeItem('token');
        window.location.href = import.meta.env.VITE_URL_FRONTEND;
    }

  return (
    <div className='text-gray-500 sm:text-lg dark:text-gray-400  sm:ml-0'>

            <div className='bg-purple-100 pt-3 pb-3  pl-5 text-gray-600 border border-purple-300 font-medium rounded-t-lg flex flex-col lg:w-1/2'>
                <div className='flex lg:justify-between  pr-5 flex-col lg:flex-row'>
                    <label className="text-center lg:text-left" >Información de la tarjeta </label>
                    <div className="flex space-x-2  items-center lg:mb-0 justify-center lg:justify-start ">
                    <img src={visa} alt="visa" className="w-10 h-10" />
                    <img src={amex} alt="Segunda imagen" className="w-10 h-10" />
                    <img src={mastercard} alt="Tercera imagen" className="w-10 h-10" />
                    <img src={discover} alt="Tercera imagen" className="w-10 h-9" />
                    </div>
                </div>
                <span className='text-sm font-medium  hidden sm:block'>Todas las transacciones son seguras y estan encriptadas</span>
            </div>

            <form onSubmit={handleSubmit}>

                <div className=' bg-gray-50 p-5  border border-gray-250 lg:w-1/2'>

                    <div className="grid gap-1 mb-3 lg:grid-cols-1">

                        <label className='font-medium'>Número de la tarjeta</label>
                        <CardNumberElement
                        options={{
                            style: {
                            base: {
                                fontSize: '15px',
                                color: '#18181b',
                                '::placeholder': {
                                color: '#aab7c4',
                                },
                                height: '40px',
                            },
                            },
                            showIcon: true,
                        }}
                        className='myInput bg-white border-gray-50 rounded-none'
                        onChange={handleInputChange}
                        name="cardNumber"
                        />
                    </div>

                    <div className="grid gap-4 mb-3 lg:grid-cols-2">

                        <div>
                            <label className='font-medium'>Fecha de vencimiento</label>
                            <CardExpiryElement
                                options={{
                                style: {
                                    base: {
                                    fontSize: '15px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                    },
                                },
                                placeholder: 'MM / AA',
                                }}
                                className='myInput bg-white mt-3'
                                onChange={handleInputChange}
                                name="cardExpiry"
                            />
                        </div>

                        <div>
                        <label className='font-medium'>Codigo de seguridad</label>
                            <CardCvcElement
                            options={{
                                style: {
                                base: {
                                    fontSize: '15px',
                                    color: '#424770',
                                    '::placeholder': {
                                    color: '#aab7c4',
                                    },
                                    height: '40px',
                                },
                                },
                                placeholder: 'Codigo de seguridad',
                            }}
                            className='myInput bg-white text-lg mt-3'
                            onChange={handleInputChange} 
                            name="cardCvc"
                            />
                        </div>

                    </div>

                    <div className="grid gap-4 mb-3 lg:grid-cols-1">
                        <div >
                            <label className='font-medium'>País</label>
                            <select className='myInput_inputs bg-white text-lg mt-1 w-full rounded-md border border-gray-300' 
                            onChange={handleChangeCountries}
                            value={listCountries}>
                                {countriesAPI.map(country => (
                                    <option key={country.alpha2Code} value={country.alpha2Code}>{country.translations.es}</option>
                                ))}
                            </select>
                        </div>
                        {viewPostal && (<div>
                        <label className='font-medium'>Codigo postal</label>
                        <input type="text" 
                               className='myInput_inputs bg-white mt-1 w-full placeholder:text-slate-400 font-medium text-gray-900 rounded-md border border-gray-300' 
                               placeholder='12345'
                               onChange={handleChangePOstal}
                               value={postal} />
                      </div>)}
                    </div>

                    <div className="">
                        <button type="submit"
                                className={`px-3 py-2 text-sm font-medium text-center text-white   hover:bg-blue-800 focus:ring-4 focus:outline-none 
                                    focus:ring-blue-300  lg:w-52 rounded-md  
                                        mt-5  ${disableButton ? 'bg-purple-700 cursor-not-allowed':'bg-gradient-purple'} `}  disabled={disableButton ? 'disabled':''}>
                                    {!disableButton ? nameEtiqueta : 'Espere un momento'}  
                                    {disableButton && (
                                        <svg aria-hidden="true" role="status" className="ml-5 inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 
                                        15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 
                                        1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 
                                        10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 
                                        35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                    </svg>
                                    )}
                        </button>
                    </div>
                </div>
                
            </form>

        
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mt-5">
                        {  error ? (<AlertErrorComponent  mensaje={messageError}
                                            titulo="Error"  />) : false}
            </div>

            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mt-5">
                <button className='px-3 py-2 text-sm font-medium text-center text-purple-600 border-bg-purple-800   hover:bg-purple-800 hover:text-white focus:ring-4 focus:outline-none 
                                            focus:ring-blue-300 w-52  mt-5 rounded-md border border-bg-blue-600 mx-auto'
                                            onClick={handleDevolverPortalTienda}>
                                                IR A LA TIENDA
                </button>
            </div>

    </div>
  )
}

export default FormPago