import { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom";
import LabelComponent from '../../../Components/Label/LabelComponent'
import RadioGroupCanal from '../RadioxGroupCanal/RadioGroupCanal'
import ChecboxGroupTermino from '../CheckboxGroupTerminos/ChecboxGroupTermino'
import Button from '../../../Components/Button/Button';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' 
import { useAppContext } from '../../../Context/ApiProviders';
import {isPossiblePhoneNumber } from 'react-phone-number-input';
import AlertErrorComponent from '../../../Components/AlertErrorComponent/AlertErrorComponent';


const Formulario = () => {
  
  const {dispatchInfoTelefono, obtenerCodigoProvider, countrieSelected, dispatchCountrieUbication, getCountrieUbicationProvider} = useAppContext();
  
  const [metodo, setMetodo] = useState('sms');
  const [numeroTelefono, setnumeroTelefono] = useState('');
  const [disabledButton, setdisabledButton] = useState(false);
  const [mensajeError, setMensajeError] = useState('')
  const [tituloError, settituloError] = useState('')
  const [error, setError] = useState(false);
  const [terminos, setTerminos] = useState(false)
  const [alert, setAlert] = useState(false)
  const [country, setCountry] = useState('')
  const [estadoCheckedSms, setEstadoCheckedSms] = useState(false)
  const [estadoCheckedCall, setEstadoCheckedCall] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    setEstadoCheckedSms(true);
    setEstadoCheckedCall(false);
  }, [])

  const handlechangeMetodo = (e) => {
    setMetodo(e.target.value);
    if(e.target.value === 'sms'){
      setEstadoCheckedSms(true);
      setEstadoCheckedCall(false);
    }else{
      setEstadoCheckedSms(false);
      setEstadoCheckedCall(true);
    }
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
   
     if(!metodo || !numeroTelefono ){
        setAlert(true);
        setdisabledButton(false);
        setMensajeError('Error Al enviar los datos');
        settituloError('Para continuar, Seleccione un metodo e ingrese número de telefono');
        return
     }
     const num = '+'+numeroTelefono;

     const validetelefono = num && isPossiblePhoneNumber(num) ? true : false;
   

     if(validetelefono === false){
        alert('asdas');
        setAlert(true);
        setMensajeError('Número de telefono invalido');
        settituloError('El telefono prporcionado no esta correcto');
        return
     }

     if(terminos === false){
        setAlert(true);
        setMensajeError('Terminos y condiciones');
        settituloError('Debe aceptar los terminos y condiciones');
        return 
     }

     setdisabledButton(true);
    
      setError(true);
      setAlert(false);
      setMensajeError('');
      settituloError('');
      
       const data = {
         'target':  '+'+numeroTelefono,
         'method' : metodo
       }

       dispatchInfoTelefono({
          type: 'ADD_TELEFONO',
          value: data
       })
   
         const resp =  await obtenerCodigoProvider(data);
        if(resp.status === 200){
          setdisabledButton(false);
          navigate('/verification-code');
        }else{
          setError(true);
          setdisabledButton(false);
          setMensajeError('Error Al enviar los datos');
          settituloError('No se pudo enviar el codigo');
        } 
     
  };
  
  const handleChange = (e) => {
    setTerminos(e.target.checked);
    if(e.target.checked === false){
      setError(false);
      setAlert(false);
    }else{
      setError(true);
    }
  };
  
  return (
    <form  onSubmit={handleSubmit}  className="space-y-2" >
      
            <LabelComponent forProps="" valueEtiqueta="Seleccione un canal" clase="text-sm" checked={true}  />
            <RadioGroupCanal name="canal" value="sms" id="sms" nombreEtiqueta="SMS" handleChange={handlechangeMetodo} checked={estadoCheckedSms && estadoCheckedSms}  />
            <RadioGroupCanal name="canal" value="call" id="call" nombreEtiqueta="LLamada" handleChange={handlechangeMetodo} checked={estadoCheckedCall && estadoCheckedCall} />
            <div>
                {/* <Outlet /> */}
                <PhoneInput
                    inputClass='mb-5 text-gray-700 font-medium  border-2 border-red-600 bg-blue-600'
                    country="us"
                    value={numeroTelefono}
                    onChange={setnumeroTelefono}
                    inputProps={{
                      required: true,
                    }}
                    enableSearch
                    //inputStyle={{ width:'50%' }}
                    inputStyle={{ borderColor:'#cbd5e1', height: '40px', fontSize:'16px' }}
                    buttonStyle={{ 
                          backgroundColor: '#fff', 
                          borderLeftColor:'#cbd5e1', 
                          borderRightColor:'#fff', 
                          borderTopColor:'#cbd5e1', 
                          borderBottomColor:'#cbd5e1'
                         }}
                    buttonProps={{ style: { height: '100%' } }}
                  />
            </div>
            <ChecboxGroupTermino id="terminos-id" value={terminos} hancleEvento={handleChange} />
            {error === true ? (<Button size="w-full lg:w-1/2" type="submit" nameEtiqueta='Validar' event={handleSubmit} disableButton={disabledButton} />) : false }
            <div className='mt-7'>
                {alert === true ? (<AlertErrorComponent mensaje={mensajeError} titulo={tituloError} />) : false}
            </div>
    </form>
  )
}

export default Formulario