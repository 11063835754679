import React from 'react'

const InputComponent = ({type, placeholder, value, evento}) => {
  return (
    <input type={type} 
           value={value}
           onChange={evento}
           className="bg-transparent  border border-gray-300    text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 
           block w-full p-2 font-medium"
                    placeholder={placeholder} 
    />
  )
}

export default InputComponent