import { useEffect, useState } from 'react'
import InputComponent from '../../../Components/Input/InputComponent';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../Context/ApiProviders';
import { toast } from 'sonner';


const Formulario = () => {

    const {postCreateCardClientProvider} = useAppContext();
    
    const [nombres, setNombres] = useState('');

    const [apellidos, setApellidos] = useState('');

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState('');
    const [mensajeError, setMensajeError] = useState('');
    const [validError, setvalidError] = useState(false);
    const [disableButton, setDisableButton] = useState(false)
    const [nameEtiqueta, setnameEtiqueta] = useState('Guardar');
    const [token_create, settoken_create] = useState('')

    useEffect(() => {
        settoken_create(sessionStorage.getItem('token_create'));
    }, [])
    
    

    const handleChangeNombre = (event) => {
        setNombres(event.target.value);
        if(nombres.length === 1) {
            setvalidError(true);
            setMensajeError('El campo Nombre es obligatorio');
        }else{
            setvalidError(false);
            setMensajeError('');
        }
    }

    const handleChangeApellido = (event) => {
        setApellidos(event.target.value);
        if(apellidos.length === 1) {
            setvalidError(true);
            setMensajeError('El campo Apellido es obligatorio');
        }else{
            setvalidError(false);
            setMensajeError('');
        }
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
        if(email.length === 1) {
            setvalidError(true);
            setMensajeError('El campo Correo electronico es obligatorio');
        }else{
            setvalidError(false);
            setMensajeError('');
        }
        
    }

    const handleChangeFechaNacimiento = (event) => {
        setFechaNacimiento(event.target.value)
        if(fechaNacimiento.length === 1) {
            setvalidError(true);
            setMensajeError('El campo fecha de nacimiento es obligatorio');
        }else{
            setvalidError(false);
            setMensajeError('');
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        
        // validar campos vacios
        if(!nombres || !apellidos || !fechaNacimiento || !email){
            setvalidError(true);
            setMensajeError('Por favor completa todos los campos.');
        }else{

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if(emailRegex.test(email)){

                setDisableButton(true);
                const param = {
                    "name": nombres,
                    'lastname' : apellidos,
                    'email' : email,
                    'birthdate' : fechaNacimiento
                };
    
                if(token_create !== null || token_create !== '' || token_create !== undefined){
                    
                    const resp = await postCreateCardClientProvider(param);
                    if(resp === 1){
                        setDisableButton(false);
                        toast.success(`Cliente se creo con éxito`, {
                            style: {
                                background: '#14532d',
                                color:'#fff',
                            },
                            duration: 10000,
                        });
                        navigate('/payment-portal');
                    }
                }
            }else{
                setvalidError(true);
                setMensajeError('Por favor, ingrese un correo electrónico válido.');
            }

        }

    }

    const handleClickCancelar = () => {
        window.location.href = 'https://ninoprodigio.com/';
    }

  return (
    <div>
          <form  onSubmit={handleSubmit}>
                    <h2 className='font-bold text-lg mb-10'>Formulario de información personal</h2>

                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">

                        <div className="w-full">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre</label>

                            <InputComponent type="text" id=""  placeholder="Escribe tu nombre"  value={nombres} evento={handleChangeNombre} />
                           
                        </div>

                        <div className="w-full">    

                                <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Apellido</label>
                                <InputComponent type="text" id=""  placeholder="Escribe tu Apellido"  value={apellidos} evento={handleChangeApellido} />
                               
                        </div>

                        <div className="sm:col-span-2">
                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Correo electronico</label>
                            <InputComponent type="text" id=""  placeholder="Escriba aquì su correo electronico"  value={email} evento={handleChangeEmail} />
                            
                        </div>

                        <div className="w-full">

                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Fecha de nacimiento</label>
                            <InputComponent type="date" id=""  placeholder="Escriba aquì su correo electronico"  value={fechaNacimiento} evento={handleChangeFechaNacimiento} />
                           
                        </div>

                    </div>
                   


                    <button type="submit"
                    //onClick={onclick}
                    className={`px-3 py-2 text-sm font-medium text-center text-white   hover:bg-cyan-800 focus:ring-4 focus:outline-none 
                         focus:ring-blue-300 w-52  mt-5 rounded  ${disableButton ? 'bg-cyan-700 cursor-not-allowed':'bg-cyan-900'} `}  disabled={disableButton ? 'disabled':''}>
                          {!disableButton ? nameEtiqueta : 'Espere un momento'}  
                          {disableButton && (
                              <svg aria-hidden="true" role="status" className="ml-5 inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 
                              15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 
                              1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 
                              10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 
                              35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                          </svg>
                          )}
            </button>


                    <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mt-5">
                            {validError ? <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 
                                        dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                    </svg>
                                    <span className="sr-only">Info</span>
                                    <div>
                                        <span className="font-medium">Advertencia</span> {mensajeError}.
                                    </div>
                            </div> : null}
                    </div>
                    <div className="gap-8 lg:grid lg:grid-cols-1 xl:gap-8">
                        <div className="text-gray-500 sm:text-lg dark:text-gray-4 mx-auto mt-10">
                           {/*  <NavLink to="https://ninoprodigio.com/"  className='text-cyan-800 underline ' >Volver a la tienda</NavLink> */}
                             <a className='text-cyan-800 underline 'href="https://ninoprodigio.com/" onClick={handleClickCancelar}>Volver a la tienda</a>
                        </div>
                    </div>
          </form>
    </div>
  )
}

export default Formulario