import { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import InfoClienteComponent from './InfoCliente/InfoClienteComponent';
import PaquetesMinutos from './PaquetesMinutos/PaquetesMinutos';
import FormPago from './Formpago/FormPago';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ModalClient from './ModalClient/ModalClient';
import { useAppContext } from '../../Context/ApiProviders';


const stripePromise = loadStripe(`${import.meta.env.VITE_STRIPE_KEY_PUBLIC}`);


const PaymentPortal = () => {

  const {infoUsers} = useAppContext();

  const navigate  = useNavigate ();
  const token = sessionStorage.getItem('token');
  const [modalOpen, setModalOpen] = useState(true);
  const [camp, setcamp] = useState('')

  useEffect(() => {
    if( token === undefined || token === '' || token === null){
      navigate('/');
    }else{
       switch (infoUsers.campaign.country) {
        case 'US':
            setcamp('+1 800 411 0112');
      break
      case 'CO' :
            setcamp('+57 800 519 00926');
      break;
      case 'RD' :
            setcamp('+1 829 947 8994');
      break;
      case 'MX' :
            setcamp('+52 800 099 1173');
      break;
      case 'EC' :
            setcamp('+593 1800 001 495');
      break;
      case 'GUA' :
            setcamp('+502 2300 7115');
      break;
      reak;
      case 'ES' :
            setcamp('+34 900 751 988');
      break;
        default:
          setcamp('+51 800 746 13');
          break;
       }
    }



  }, []);


  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    appearance: {
      theme: 'stripe',
      rules: {
        '.Tab': {
          border: '1px solid #E0E6EB',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
        }
      }
    }
      
  };

  const handleCerrarModal = () => {
    setModalOpen(false);
}
  


  return (
    <div className="py-6 px-4 mx-auto space-y-12 max-w-screen-2xl lg:space-y-10 sm:py-16 lg:px-6">
      

      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-[1fr,2fr] gap-4">

          <div className="bg-white p-4">
                <h2 className='text-xl font-bold mb-2 text-gray-600 pl-5'>Mis datos personales</h2>
                    <InfoClienteComponent   />
          </div>

          <div className="bg-white p-4">

              <h2 className='text-xl font-bold mb-2 text-gray-600 '>Paquetes Disponibles</h2>
              <div class="flex items-center p-4 mb-4 text-sm text-purple-800 border border-purple-300 rounded-lg bg-purple-50 dark:bg-gray-800" role="alert">
                <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span class="sr-only">Info</span>
                <div>
                <span class="font-medium">Nota: </span> 
                <p>
                     <span className='mb-2'>
                        Si nuestros paquete de minutos actual no son de su preferencia y desea conocer más sobre nuestras promociones especiales, por favor, no dude en contactarnos. 
                      </span> <br />
                     <span className='mb-2'>
                        Estamos aquí para ayudarle a encontrar la opción que mejor se adapte a sus necesidades.
                        Llame a nuestra línea de ventas al número <br /><span className='font-medium'>{camp && camp}</span>  y con gusto le proporcionaremos toda la información necesaria para que pueda aprovechar nuestras ofertas exclusivas.
                     </span>
                </p>
                </div>
              </div>
              <hr className='divide-y mb-3' />
              <PaquetesMinutos />
              <h2 className='text-xl font-bold mb-2 text-gray-600 mt-10'>Información de la tarjeta</h2>
              <Elements stripe={stripePromise} options={options}>
                <FormPago/>
              </Elements>
          </div>
          <ModalClient isOpen={modalOpen} onClose={handleCerrarModal}/>

      </div>



    </div>
  )
}

export default PaymentPortal