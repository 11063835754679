import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HeaderComponent from './Components/Header/HeaderComponent';
import { PhoneValidation } from './Pages/PhoneValidation/PhoneValidation';
import VerificationCode from './Pages/VerificationCode/VerificationCode';
import Clients from './Pages/Clients/Clients';
import PaymentPortal from './Pages/PaymentPortal/PaymentPortal';
import SalesSummarys from './Pages/SalesSummarys/SalesSummarys';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import InputPhoneGroup from './Pages/PhoneValidation/InputPhoneGropup/InputPhoneGroup';
import { Toaster } from 'sonner';
import Footer from './Components/Footer/Footer';
import { useEffect } from 'react';
import fondocuerpo from './assets/fondo-cuerpo.png'



function App() {

  useEffect(() => {
    // Función para manejar el evento beforeunload
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('token');
      window.location.href = 'https://agenda.ninoprodigio.com/';
    };

    // Agregar el event listener para beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (

    <BrowserRouter>
        <HeaderComponent />
        <Toaster position="bottom-right" closeButton  />
        
        <section className="bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${fondocuerpo})` }}>
            <Routes>
                <Route path="/" element={<PhoneValidation />} >
                    *<Route index  element={<InputPhoneGroup />} />**
                </Route>
                <Route path="/verification-code" element={<VerificationCode />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/payment-portal" element={<PaymentPortal />} />
                <Route path="/sales-summarys" element={<SalesSummarys />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </section>
        {/* Footer */}
        <Footer />

    </BrowserRouter>
  )
}

export default App
