import { PortalPagoPaquetes } from '../../Context/PortalPagoPaquetes';
import { PortalPagoContent } from '../../Context/PortalPagoContent';
import Formulario from './Formulario/Formulario';


const Clients = () => {

  return (
    
    <PortalPagoContent>
      <PortalPagoPaquetes>
        <div className="py-6 px-4 mx-auto space-y-12 max-w-screen-md lg:space-y-10 sm:py-16 lg:px-6">

        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            <div className="bg-white p-4">
                <Formulario />
            </div>
        </div>

        </div>
        </PortalPagoPaquetes>
      </PortalPagoContent>
  )
}

export default Clients