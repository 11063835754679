import React, {createContext, useRef, useState} from 'react'
import { useNavigate, useLocation   } from 'react-router-dom';
import {isPossiblePhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber  } from 'libphonenumber-js';
import { toast } from 'sonner';


// peticiones HTTP
import { obtenerCodigo, veririfcarCodigo } from '../Services/ApiHttp';
import { NotificacionesToastError, NotificacionesToastSuccess } from '../Components/NotificacionesToast/NotificacionesToast';

export const PortalPagoContext = createContext();
export const PortalPagoContent = (props) => {

  const navigate  = useNavigate ();

  const [numeroTel, setNumeroTel] = useState('');
  const [numeroTelParam, setnumeroTelParam] = useState('');
  const [selectRadio, setSelectRadio] = useState('sms');
  const [cboxterminos, setCboxTerminos] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false)
  const [disabledButtonCode, setDisabledButtonCode] = useState(false)
  const [Token, setToken] = useState('');
  const [validToken, setValidToken] = useState(false)
  const [email, setEmail] = useState();

  // Estados ventana portal de pago



  const codeVerify = useRef('');
  



  //* Validaciòn de nùmero de telefono
  const validarTelefono = async () => {
    
    //* validación de campos vacios
    if(numeroTel === '') {

        if(selectRadio === 'email'){

          NotificacionesToastError(`Por favor, asegúrese de completar el campo de correo electrónico.`);
          
        }else{

          NotificacionesToastError(`Por favor, asegúrese de completar el campo de teléfono.`);

        }
    }else{
      if(selectRadio === 'email'){

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(emailRegex.test(numeroTel)){

          if(cboxterminos === false){

              NotificacionesToastError(`Por favor, asegúrese de aceptar los términos de tratamiento de datos.`);
            
          }else{

            setDisabledButton(true);
            
            const param = {
              'method':selectRadio,
              'target':numeroTel
            }

            const res = await obtenerCodigo(param);
            respuestatelefono(param);
            setDisabledButton(false);
            
            NotificacionesToastSuccess(`Se ha enviado un código al correo proporcionado.`);
          }
        }else{

          NotificacionesToastError(`Por favor, ingrese un correo electrónico válidos.`);
        }

      }else{
        let num = '+'+numeroTel;
        //* validamos que el formato del numero sea correcto
        const res = num && isPossiblePhoneNumber(num) ? true : false;

        //* Validamos que se chequeen los terminos
        if(res === true){
          const resVeri = verifyNumber(num);
          
          if(cboxterminos === false){

            NotificacionesToastError(`Por favor, asegúrese de aceptar los términos de tratamiento de datos.`);
            
          }else{

            setDisabledButton(true);
            const param = {
              'method':selectRadio,
              'target':num
            }
            const res = await obtenerCodigo(param);
            respuestatelefono(param);
            setDisabledButton(false);

            NotificacionesToastSuccess(`Se ha enviado un código al número de teléfono proporcionado..`);
          }
  
        }else{

          NotificacionesToastError(`Lo sentimos, el formato del número no es válido. Por favor, verifique y vuelva a intentarlo.`);
        }
      }
    }
    
  }

  const respuestatelefono =  (param) => {
    navigate("/verification-code", {state: {nume:param.target}});
  }

  const verifyNumber =  (numero) => {
      const phoneNumber =  parsePhoneNumber(numero);
      return phoneNumber;
  };


  const handleCanal = (param) => {
     setSelectRadio(param);
  }

  const handleTerminos = (param) => {
    setCboxTerminos(param);
  };

  const verifyCodeHandle = async (num) => {

      setDisabledButtonCode(true);
      const code = codeVerify.current.value;

      if(code !== ''){
        setnumeroTelParam(num);

        const param2 = {
          'code':codeVerify.current.value,
          'method': selectRadio,
          'target':num
        }

        const resp = await veririfcarCodigo(param2);

        if(resp.user){
          sessionStorage.setItem('token', resp.token);
          setToken(resp.token);
          setValidToken(true);
          navigate("/payment-portal", {state: {param:resp}});
        }else{
          sessionStorage.setItem('token_create', resp.token);
          navigate("/clients", {state: {token:resp.token}});
        }
        setDisabledButtonCode(false);
      }else{

        NotificacionesToastError(`El código de verificación ingresado es incorrecto..`);
      }
  }
  

  return (
    <PortalPagoContext.Provider value={{
        objStatusPhone : { numeroTel, setNumeroTel, validarTelefono},
        selectRadio, setSelectRadio, handleCanal,
        setCboxTerminos, handleTerminos, cboxterminos,
        setEmail, email,
        codeVerify, verifyCodeHandle, setnumeroTelParam, respuestatelefono, numeroTelParam, 
        disabledButton, setDisabledButton,
        disabledButtonCode, setDisabledButtonCode,
        Token, validToken,
    }} >
      {props.children}
    </PortalPagoContext.Provider>
    
  )
}

