import React, {createContext,  useState} from 'react'
import { getListaPaquetes, getInfoCLient, getInfoTarjetasCliente, postPay } from '../Services/ApiHttp';

export const PortalPagoPaquetesContext = createContext();
export const PortalPagoPaquetes = (props) => {

    const [radioCompraPaquete, setRadioCompraPaquete] = useState('')
    const [radioValuePaquete, setRadioValuePaquete] = useState('')
    const [dataInfoCLient, setdataInfoCLient] = useState(null)
    const [cardClient, setcardClient] = useState(null)
    const [paquetes, setPaquetes] = useState(null)
    const [objPaquete, setObjPaquete] = useState(null)

    const [cardContext, setCardContext] = useState(null)
    const [codigoCVC, setCodigoCVC] = useState('')

    const handleStadoPaquetes = (param) => {
        setRadioCompraPaquete(param);
    }

    const handleGetInfoClients = async(client_id, token) => {
       
        const parametros = {
            'client_id': client_id,
            'token':token
        }
        const resp = await getInfoCLient(parametros);
        setdataInfoCLient(resp);
        return resp;
    }

    const handleCardsClient = async (id, camp, token) => {
        
        const param = {
            'client_id': id,
            'camp':camp.length === 0 ? null : camp.campaign_name,
            'token':token
        }
       const resp = await getInfoTarjetasCliente(param);
       return resp;
    }

    const handlePaquetesMinutos = async (camp, token) => {
        const param = {
            'camp':camp.length === 0 ? null : camp.campaign_name,
            'token':token
        }
            const resp = await  getListaPaquetes(param);
            return resp;
    }



    const handleComprar = async (param, token) => {
        const resp = await postPay(param, token);
        return resp;
     }

  return (
    <PortalPagoPaquetesContext.Provider value={{
        setRadioCompraPaquete, 
        radioCompraPaquete, 
        setRadioValuePaquete, 
        radioValuePaquete, 
        handleStadoPaquetes,
        handleGetInfoClients,
        setdataInfoCLient,
        dataInfoCLient,
        cardClient,
        handlePaquetesMinutos,
        paquetes, setPaquetes,
        setObjPaquete, objPaquete,
        handleCardsClient,
        cardContext, setCardContext,
        handleComprar
    }} >
      {props.children}
    </PortalPagoPaquetesContext.Provider>
  )
}
