import React from 'react'

const ButtonT = ({event}) => {
  return (
    <div>
            <button type="button"
                    onClick={event}
                    className="px-3 py-2 w-full text-sm font-medium text-center text-purple-600 hover:bg-purple-800 hover:text-white focus:ring-4 focus:outline-none rounded
                    focus:ring-blue-300 mt-5 bg-white border border-purple-600">
                   Mis datos estan correctos     
            </button>
    </div>
  )
}

export default ButtonT