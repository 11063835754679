import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../Context/ApiProviders';


const SummaryComponent = () => {

    const {paqueteSelected, infoUsers, cards, discount, infoTelefono} = useAppContext();
     const [valorfinal, setvalorfinal] = useState('')
     const [valor, setValor] = useState(0)
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        if(!token){
            //navigate('/');
        }else{
            const calculo = (paqueteSelected.amount * discount.porcentaje) / 100;
            setvalorfinal(paqueteSelected.amount - calculo);
            setValor(infoUsers.timeAllocations.day + infoUsers.timeAllocations.night + infoUsers.timeAllocations.specific_time  + infoUsers.timeAllocations.time_allocated +  paqueteSelected.minutes);
        }
        
    }, [])
    
    const enviacorreo = () => {
        sessionStorage.removeItem('token');
        window.location.href = import.meta.env.VITE_URL_FRONTEND;
    }

  return (
    <div className='bg-white p-5 rounded-lg shadow-lg'>

        <div class="p-4 mb-4 text-sm text-green-50 rounded-lg bg-green-600 dark:bg-gray-800 dark:text-green-400" role="alert">
        <span className='font-medium text-xl flex items-center'>
            <svg class="w-6 h-6 text-gray-50 dark:text-white mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
            </svg>

            Felicidades, La compra fue aprobada.</span>
        </div>

        <h2 className=" text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white mb/5">Resumen de compra</h2>

        <div className="gap-8 lg:grid lg:grid-cols-2 xl:gap-8">

            <div className="text-gray-500 sm:text-lg dark:text-gray-4">

            <h2 className="mb-4 text-lg tracking-tight font-extrabold text-gray-600 dark:text-white mt-2">Información personal</h2>
            <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                        <div className="flex flex-col pb-1">
                            <dt className="lg:text-lg-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Nombress</dt>
                            <dd className="text-sm font-semibold">{ infoUsers  !== null? infoUsers.full_name : ''}</dd>
                
                        </div>
                        <div className="flex flex-col py-1">
                            <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Correo Electronico</dt>
                            <dd className="text-sm font-semibold">{ infoUsers  !== null? infoUsers.email: '' }</dd>
                        </div>
                        <div className="flex flex-col pt-1">
                            <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Número de teléfono</dt>
                            <dd className="text-sm font-semibold">{ infoUsers  !== null? infoTelefono.target : ''}</dd>
                        </div>
                        <div className="flex flex-col pt-1">
                            <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Minutos disponibles</dt>
                            <dd className="text-sm font-semibold">{ valor  && valor }</dd>
                        </div>
                        {infoUsers?.member_type?.name && (<div className="flex flex-col pt-1">
                            <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Membresia</dt>
                            <dd className="text-sm font-semibold flex items-center">
                                <svg class={`w-6 h-6 mr-2
                                            ${infoUsers.member_type.name === 'BASIC' ? "text-red-950" : 
                                            infoUsers.member_type.name === 'VIP' ? "text-gray-400" : "text-yellow-400"}`} 
                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/>
                                    <path fill-rule="evenodd" d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" clip-rule="evenodd"/>
                                    <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z"/>
                                </svg>
                                {infoUsers.member_type.name }
                            </dd>
                        </div>)}
                        
            </dl>
            
            </div>

            <div className="text-gray-500 sm:text-lg dark:text-gray-4">
                <h2 className="mb-4 text-lg tracking-tight font-extrabold text-gray-600 dark:text-white mt-2">Información del producto</h2>

                <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="flex flex-col pb-1">
                        <dt className="lg:text-lg-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Producto</dt>
                        <dd className="text-sm font-semibold uppercase">{paqueteSelected ? paqueteSelected.minutes: 0} minutos de consulta</dd>
                
                        </div>
                        <div className="flex flex-col py-1">
                            <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Precio normal</dt>
                            <dd className="text-sm font-semibold">${ paqueteSelected?.amount && parseFloat( paqueteSelected.amount).toFixed(2)} USD</dd>
                        </div>

                        {
                            infoUsers.birthday_this_month === false ?
                             (
                                infoUsers?.member_type?.name && (
                                    <div className="flex flex-col pt-1">
                                        <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Porcentaje de descuento</dt>
                                        <dd className="text-sm font-semibold">
                                            {discount.porcentaje}%
                                        </dd>
                                    </div>
                                )
                             ) 
                             : 
                             (
                                <div className="flex flex-col pt-1">
                                    <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Porcentaje de descuento</dt>
                                    <dd className="text-sm font-semibold">
                                        15%
                                    </dd>
                                </div>
                             )
                        }
                        
                        {/* {infoUsers?.member_type?.name && (<div className="flex flex-col pt-1">
                            <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Porcentaje de descuento</dt>
                            <dd className="text-sm font-semibold">
                                {discount.porcentaje}%
                            </dd>
                        </div>)} */}
                        

                        <div className="flex flex-col pt-1">
                            <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Valor pagado</dt>
                            <dd className="text-sm font-semibold">${valorfinal  && parseFloat(valorfinal).toFixed(2)} USD</dd>
                        </div>
                </dl>
            </div>

        </div>

        <div className="gap-4 lg:grid lg:grid-cols-1 lg:gap-4 mt-10">
            <div className="text-gray-500 sm:text-lg dark:text-gray-4 ">
                <h2 className="mb-3 text-lg tracking-tight font-extrabold text-gray-600 dark:text-white">Información de metodo de pago</h2>
                <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                            <div className="flex flex-col pb-1">
                            <dt className="lg:text-lg-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Metodo de pago</dt>
                            <dd className="text-sm font-semibold">T. {cards.funding}</dd>
                    
                            </div>
                            <div className="flex flex-col py-1">
                                <dt className="lg:text-sm mb-1 text-gray-500 md:text-sm dark:text-gray-400">Numero de tarjeta terminada en</dt>
                                <dd className="text-sm font-semibold">{cards.last4}</dd>
                            </div>
                </dl>
            </div>
        </div>

        <div className="gap-8 lg:grid lg:grid-cols-1 xl:gap-8">
            <div className="text-gray-500 sm:text-lg dark:text-gray-4 mx-auto mt-10">
                <NavLink to="https://agenda.ninoprodigio.com/"  className='text-blue-600 font-medium underline ' >Ir al portal</NavLink>
            </div>
        </div>
        
    </div>
  )
}

export default SummaryComponent