import React from 'react'

const RadioxPackage = ({name, value, id, evento}) => {
   
  return (
    <input  id={id} 
            type="radio" 
            value={value}
            name={name}
            onChange={evento}
            className="w-4 h-4 text-purple-700 bg-gray-100 border-purple-600 focus:ring-purple-800 
                     focus:ring-2" />
  )
}

export default RadioxPackage